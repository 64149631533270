/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import 'typeface-open-sans'
import { anchorate } from 'anchorate'

export const onRouteUpdate = () =>
  anchorate({
    scroller: function(element) {
      if (!element) return false
      element.scrollIntoView({ behavior: 'smooth' })
      return true
    },
  })
